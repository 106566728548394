<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: "PingFangSC","Microsoft Yahei","微软雅黑","Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
ol,
ul {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
}
body,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #000000;
}

::-webkit-scrollbar {
  display: none;
}
</style>
