
import { defineComponent, ref, reactive } from "vue";
import { buriedPoint } from "../api/http";
import { getDate } from "../utils/dict";

export default defineComponent({
  name: "TopBar",
  setup() {
    const tabTagOne = ref(false);
    const tabTagTwo = ref(false);
    const contactTag = ref(false);
    const mobileTag = ref(0);
    const menuTag = ref(0);
    const urlTag = ref(0);
    const params = reactive({
      yearMonthDay: "",
      code: "",
    });
    const mouseOver = () => {
      tabTagOne.value = true;
    };
    const mouseLeave = () => {
      tabTagOne.value = false;
    };
    const mouseOverTwo = () => {
      tabTagTwo.value = true;
    };
    const mouseLeaveTwo = () => {
      tabTagTwo.value = false;
    };
    const handleSubmit = (e: any) => {
      params.yearMonthDay = getDate();
      params.code = e;
      buriedPoint(params).then((response) => {
        const res: any = response.data;
        if (res.code === 200) {
          // console.log("000");
        }
      });
    };
    const windowWidth = ref(document.documentElement.clientWidth);
    let getUrl = () => {
      const url = window.location.href;
      if (url.indexOf("PaymentPage") != -1) {
        urlTag.value = 1;
      } else if (url.indexOf("RobotPage") != -1) {
        urlTag.value = 2;
      } else if (url.indexOf("LockPage") != -1) {
        urlTag.value = 3;
      } else if (url.indexOf("MeasurePage") != -1) {
        urlTag.value = 4;
      } else if (url.indexOf("ProductMainPage") != -1) {
        urlTag.value = 5;
      } else if (url.indexOf("ServicePage") != -1) {
        urlTag.value = 6;
      } else if (url.indexOf("IntroducePage") != -1) {
        urlTag.value = 7;
      } else if (url.indexOf("NewsPage") != -1) {
        urlTag.value = 8;
      } else if (url.indexOf("JoinPage") != -1) {
        urlTag.value = 9;
      }
    };
    getUrl();
    return {
      tabTagOne,
      tabTagTwo,
      contactTag,
      params,
      mouseOver,
      mouseLeave,
      mouseOverTwo,
      mouseLeaveTwo,
      handleSubmit,
      windowWidth,
      mobileTag,
      menuTag,
      urlTag,
      getUrl,
    };
  },
});
