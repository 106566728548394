
import { defineComponent, reactive, ref } from "vue";
import { buriedPoint } from "../api/http";
import { getDate } from "../utils/dict";

export default defineComponent({
  name: "FooterBar",
  setup() {
    const params = reactive({
      yearMonthDay: "",
      code: "",
    });
    const handleSubmit = (e: any) => {
      params.yearMonthDay = getDate();
      params.code = e;
      buriedPoint(params).then((response) => {
        const res: any = response.data;
        if (res.code === 200) {
          // console.log("000");
        }
      });
    };
    const windowWidth = ref(document.documentElement.clientWidth);
    const menuTag = ref(0);
    return {
      params,
      handleSubmit,
      windowWidth,
      menuTag,
    };
  },
});
