
import { defineComponent, reactive, ref } from "vue";
import TopBar from "@/components/TopBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import { buriedPoint } from "../api/http";
import { getDate } from "../utils/dict";

export default defineComponent({
  name: "HomeView",
  components: {
    TopBar,
    FooterBar,
  },
  setup() {
    const contactTag = ref(false);
    const carouselIndex = ref(0);
    const videoTag = ref(false);
    const onCarouselChange = (index: any) => {
      carouselIndex.value = index;
    };
    const params = reactive({
      yearMonthDay: "",
      code: "",
    });
    const handleSubmit = (e: any) => {
      params.yearMonthDay = getDate();
      params.code = e;
      buriedPoint(params).then((response) => {
        const res: any = response.data;
        if (res.code === 200) {
          // console.log("000");
        }
      });
    };
    const ovevVideo = () => {
      videoTag.value = true;
    };
    const leaveVideo = () => {
      videoTag.value = false;
    };
    const windowWidth = ref(document.documentElement.clientWidth);
    return {
      contactTag,
      params,
      videoTag,
      carouselIndex,
      onCarouselChange,
      handleSubmit,
      ovevVideo,
      leaveVideo,
      windowWidth,
    };
  },
});
