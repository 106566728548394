import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ProductMainPage',
    name: 'ProductMainPage',
    component: () => import('../views/ProductView/ProductMainPage.vue')
  },
  {
    path: '/VenusLiteQ',
    name: 'VenusLiteQ',
    component: () => import('../views/ProductView/VenusLiteQ.vue')
  },
  {
    path: '/Venus-Lite P1',
    name: 'Venus-Lite P1',
    component: () => import('../views/ProductView/Venus-Lite P1.vue')
  },
  {
    path: '/Venus-LiteS',
    name: 'Venus-LiteS',
    component: () => import('../views/ProductView/Venus-LiteS.vue')
  },
  {
    path: '/Venus-LiteX',
    name: 'Venus-LiteX',
    component: () => import('../views/ProductView/Venus-LiteX.vue')
  },
  {
    path: '/NuwaXB40',
    name: 'NuwaXB40',
    component: () => import('../views/ProductView/NuwaXB40.vue')
  },
  {
    path: '/NuwaX100',
    name: 'NuwaX100',
    component: () => import('../views/ProductView/NuwaX100.vue')
  },
  {
    path: '/NuwaHP60',
    name: 'NuwaHP60',
    component: () => import('../views/ProductView/NuwaHP60.vue')
  },
  {
    path: '/NuwaHP60C',
    name: 'NuwaHP60C',
    component: () => import('../views/ProductView/NuwaHP60C.vue')
  },
  {
    path: '/NuwaHP60V',
    name: 'NuwaHP60V',
    component: () => import('../views/ProductView/NuwaHP60V.vue')
  },
  {
    path: '/Nuwa-Lidar120',
    name: 'Nuwa-Lidar120',
    component: () => import('../views/ProductView/Nuwa-Lidar120.vue')
  },
  {
    path: '/Nuwa-Lidar120C',
    name: 'Nuwa-Lidar120C',
    component: () => import('../views/ProductView/Nuwa-Lidar120C.vue')
  },
  {
    path: '/VegaU20L',
    name: 'VegaU20L',
    component: () => import('../views/ProductView/VegaU20L.vue')
  },
  {
    path: '/VegaU21C',
    name: 'VegaU21C',
    component: () => import('../views/ProductView/VegaU21C.vue')
  },
  {
    path: '/ProximaM10AS',
    name: 'ProximaM10AS',
    component: () => import('../views/ProductView/ProximaM10AS.vue')
  },
  {
    path: '/AuroraR1200',
    name: 'AuroraR1200',
    component: () => import('../views/ProductView/AuroraR1200.vue')
  },
  {
    path: '/PurpleLi20',
    name: 'PurpleLi20',
    component: () => import('../views/ProductView/PurpleLi20.vue')
  },
  {
    path: '/Purple-Mix120',
    name: 'Purple-Mix120',
    component: () => import('../views/ProductView/Purple-Mix120.vue')
  },
  {
    path: '/DOEMaster',
    name: 'DOEMaster',
    component: () => import('../views/ProductView/DOEMaster.vue')
  },
  {
    path: '/GratingMaster',
    name: 'GratingMaster',
    component: () => import('../views/ProductView/GratingMaster.vue')
  },
  {
    path: '/PaymentPage',
    name: 'PaymentPage',
    component: () => import('../views/SolveView/PaymentPage.vue')
  },
  {
    path: '/RobotPage',
    name: 'RobotPage',
    component: () => import('../views/SolveView/RobotPage.vue')
  },
  {
    path: '/LockPage',
    name: 'LockPage',
    component: () => import('../views/SolveView/LockPage.vue')
  },
  {
    path: '/MeasurePage',
    name: 'MeasurePage',
    component: () => import('../views/SolveView/MeasurePage.vue')
  },
  {
    path: '/ServicePage',
    name: 'ServicePage',
    component: () => import('../views/ServiceView/ServicePage.vue')
  },
  {
    path: '/NewsPage',
    name: 'NewsPage',
    component: () => import('../views/AboutView/NewsPage.vue')
  },
  {
    path: '/JoinPage',
    name: 'JoinPage',
    component: () => import('../views/AboutView/JoinPage.vue')
  },
  {
    path: '/IntroducePage',
    name: 'IntroducePage',
    component: () => import('../views/AboutView/IntroducePage.vue')
  },
  {
    path: '/TestPage',
    name: 'TestPage',
    component: () => import('../views/TestView/TestPage.vue')
  },
  {
    path: '/NuwaXPage',
    name: 'NuwaXPage',
    component: () => import('../views/AdvertiseView/NuwaXPage.vue')
  },
  {
    path: '/LitePage',
    name: 'LitePage',
    component: () => import('../views/AdvertiseView/LitePage.vue')
  },
  {
    path: '/LidarPage',
    name: 'LidarPage',
    component: () => import('../views/AdvertiseView/LidarPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

export default router
